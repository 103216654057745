<template>
  <v-app style="background-color: #f5f8fc">
    <AppToolbarVue v-if="isAuthVisible" />
    <AppDrawerVue v-if="isAuthVisible" />
    <AppSnackbarVue/>
    <v-main style="height: 100%" v-if="!checkingStatus">
      <v-slide-y-reverse-transition>
      <router-view v-show="show" />
    </v-slide-y-reverse-transition>
    </v-main>
    <v-main v-else>
      <v-container fluid fill-height class="full-height" style="height: 100%;">
        <v-row justify="center" align="center">
          <v-col md="12" class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawerVue from "./components/core/AppDrawer.vue";
import AppSnackbarVue from './components/core/AppSnackbar.vue';
import AppToolbarVue from "./components/core/AppToolbar.vue";

export default {
  name: "App",
  components: {
    AppToolbarVue,
    AppDrawerVue,
    AppSnackbarVue
  },
  computed: {
    isAuthVisible: {
      get() {
        return this.$store.state.authStore.currentUser;
      },
    },
  },
  data: () => ({
    show: false,
    checkingStatus: false,
  }),
  created() {
    this.show = true;
  },
  async beforeCreate() {
    this.checkingStatus = true;
    // await this.$store.dispatch("authStore/FetchUser");
    this.checkingStatus = false;
  },
};
</script>
