<template>
  <v-app-bar
    app
    fixed
    clipped-left
    class="black--text"
    color="#F5F8FC"
    elevation="0"
    height="70"
  >
    <v-app-bar-nav-icon
      aria-label="Hamburger Btn"
      @click="toggleDrawer"
    ></v-app-bar-nav-icon>

    <v-toolbar-title class="px-0">
      <router-link
        to="/"
        class="grey--text text--darken-2"
        style="text-decoration: none; font-size: 110%"
      >
        CII Admin</router-link
      >
    </v-toolbar-title>

    <!-- <v-autocomplete
        :items="pages"
        cache-items
        flat
        hide-details
        label="Search Page"
        solo
        item-text="name"
        style="border-radius: 12px"
        prepend-inner-icon="mdi-layers-search-outline"
        @keypress="showData"
      >
    </v-autocomplete> -->

    <!-- <v-text-field
      class="my-0"
      hide-details
      style="border-radius: 12px"
      flat
      solo
      prepend-inner-icon="mdi-layers-search"
      placeholder="Search Pages"
    >
    </v-text-field> -->

    <v-spacer></v-spacer>
    <!-- <v-chip color="warning" style="color: black" class="mx-3"
      >Super Admin</v-chip
    > -->
    <v-btn fab small text color="#616161">
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          text
          color="#616161"
          class="mx-3"
          target="_blank"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <span>User Guide</span>
    </v-tooltip>
    <v-btn
      @click="$store.dispatch('authStore/LogoutUser')"
      color="error"
      rounded
      outlined
    >
      <span class="mr-2">Logout</span>
      <v-icon small>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>
    
<script>
export default {
  name: "AppToolbar",
  components: {},
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {
    toggleDrawer() {
      this.$store.commit("TOGGLE_DRAWER");
    },
  },
};
</script>
    
    <style>
</style>