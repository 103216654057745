import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { auth } from "@/config/firebase";
import {
  onAuthStateChanged
} from "firebase/auth";

Vue.config.productionTip = false


onAuthStateChanged(auth, async (user) => {
  new Vue({
    router,
    store,
    vuetify,
    async created() {
      if(user) {
        // User is signed in
        console.log('User is signed in:', user);
        await this.$store.dispatch("authStore/FetchUser");
      } else {
        // User is signed out
        if (window.location.pathname != '/login') {
          router.push('/login')
        }
        console.log('User is signed out');
      }

    },
    render: h => h(App)
  }).$mount('#app')
})
