import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiredAuth: true,
      title: "Home"
    }
  },
  {
    path: '/universities',
    name: 'universities',
    component: () => import(/* webpackChunkName: "universities-page" */ '../views/UniversitiesPage.vue'),
    meta: {
      requiredAuth: false,
      title: "Universities"
    }
  },
  {
    path: '/leads',
    name: 'leads',
    component: () => import(/* webpackChunkName: "leads-page" */ '../views/LeadsPage.vue'),
    meta: {
      requiredAuth: false,
      title: "Leads"
    }
  },
  {
    path: '/universities/:id',
    name: 'university-info',
    component: () => import(/* webpackChunkName: "university-info-page" */ '../views/UniversityPage.vue'),
    meta: {
      requiredAuth: false,
      title: "University Info"
    }
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import(/* webpackChunkName: "courses-page" */ '../views/CoursesPage.vue'),
    meta: {
      requiredAuth: false,
      title: "Courses"
    }
  },
  {
    path: '/courses/:uid/:cid',
    name: 'course-info',
    component: () => import(/* webpackChunkName: "course-info" */ '../views/CoursePage.vue'),
    meta: {
      requiredAuth: false,
      title: "Course Info"
    }
  },
  {
    path: '/programs',
    name: 'programs',
    component: () => import(/* webpackChunkName: "programs-page" */ '../views/ProgramsPage.vue'),
    meta: {
      requiredAuth: false,
      title: "Program Info"
    }
  },
  {
    path: '/programs/:id',
    name: 'program-info',
    component: () => import(/* webpackChunkName: "program-info" */ '../views/ProgramPage.vue'),
    meta: {
      requiredAuth: false,
      title: "Program Info"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login-page" */ '../views/LoginPage.vue'),
    meta: {
      requiredAuth: false,
      title: "Login"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isAuth = store.state.authStore.currentUser
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (!isAuth) {
      document.title = to.meta.title + ' | CII Admin'
      next('/login')
      return
    } else {
      document.title = to.meta.title + ' | CII Admin'
      next()
      return
    }
  } else {
    document.title = to.meta.title + ' | CII Admin'
    next()
    return
  }
})

export default router
