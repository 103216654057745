import Vue from 'vue'
import Vuex from 'vuex'
import authStore from './modules/auth.store'
import projectStore from './modules/project.store'
import universityStore from './modules/university.store'
import courseStore from './modules/course.store'
import programStore from './modules/program.store'
import sequenceStore from './modules/sequence.store'
import leadStore from './modules/lead.store'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    snackbar: {
      isSnackbarVisible: false,
      snackbarText: ''
    }
  },
  getters: {
    GET_SNACKBAR: (state) => state.snackbar
  },
  mutations: {
    SET_DRAWER: (state, payload) => (state.drawer = payload),
    TOGGLE_DRAWER: (state) => (state.drawer = !state.drawer),
    SET_SNACKBAR: (state, payload) => (state.snackbar.isSnackbarVisible = true, state.snackbar.snackbarText = payload),
    TOGGLE_SNACKBAR: (state) => (state.snackbar.isSnackbarVisible = !state.snackbar.isSnackbarVisible)
  },
  actions: {
  },
  modules: {
    authStore,
    projectStore,
    universityStore,
    courseStore,
    programStore,
    sequenceStore,
    leadStore
  }
})
