import { collectionName } from "@/config/collections";
import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    getDoc,
    deleteDoc,
    orderBy,
    query,
    collectionGroup
    // setDoc
} from "firebase/firestore"

const state = {
    allUniversities: []
}

const getters = {

}

const mutations = {
    doNothing: (state) => state
}

const actions = {
    /**
     * @description Add University
     * @param {Object} data 
     * @returns {Object} Object
    */
    async addUniversity({ commit, rootState }, data) {
        data.createdAt = serverTimestamp()
        data.createdBy = rootState.authStore.currentUser.uid

        // const sequentialId = await dispatch('sequenceStore/getNextSequentialId', collectionName.universities,  { root: true })
        // data.id = sequentialId
        // console.log('sadsdd',data);

        return new Promise((resolve, reject) => {
            addDoc(collection(db, collectionName.universities), data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'University Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    /**
     * @description Get All Universities
     * @returns {Object} Object
    */
    async getUniversities({ commit }) {
        let universities = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName.universities)).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    universities.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: universities
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get All Universities By Order
     * @returns {Object} Object
    */
    async getUniversitiesByOrder({ commit }) {
        let universities = []
        let orderQuery = query(collection(db, collectionName.universities), orderBy('createdAt', 'desc'));

        return new Promise((resolve, reject) => {
            getDocs(orderQuery).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    universities.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                console.log('asd', universities);
                resolve({
                    success: true,
                    data: universities
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },


    /**
     * @description Update University
     * @param {Object} - Updated Data with Doc Id
     * @returns {Object} Object
    */
    async updateUniversity({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName.universities, data.docid)
            data.updatedAt = serverTimestamp()
            data.updatedBy = rootState.authStore.currentUser.uid
            delete data.docid
            updateDoc(docRef, data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'University Updated'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get University info by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getUniversityById({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName.universities, docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Remove University by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async removeUniversity({ commit }, docid) {
        return new Promise((resolve, reject) => {
            deleteDoc(doc(db, collectionName.universities, docid)).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'University Removed Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    /**
     * @description Add Course into University
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async addCourseToUniversity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const universityRef = doc(db, collectionName.universities, payload.university);
            addDoc(collection(universityRef, 'courses'), payload.course).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Courses Added Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    /**
     * @description Update Course into University
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async updateCourseToUniversity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            // Reference to the course document within the 'courses' subcollection
            const courseRef = doc(db, collectionName.universities, payload.university, collectionName.courses, payload.course.docid);
            console.log('payload.course', payload.course);
            updateDoc(courseRef, payload.course).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Courses Updated Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    /**
     * @description Get All Courses with University Info
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getAllCoursesWithUniversity({ commit },) {
        return new Promise((resolve, reject) => {
            // Array to store courses with their universities
            const coursesWithUniversities = [];

            // Query all documents from the 'courses' subcollection across all universities
            const q = query(collectionGroup(db, collectionName.courses));

            // Execute the query 
            getDocs(q).then(querySnapshot => {
                // Iterate through the query snapshot
                querySnapshot.forEach(async (doc) => {
                    // Get course data
                    let courseData = doc.data();
                    courseData = { ...courseData, ...{ docid: doc.id } }
                    // Extract university data from the parent document
                    let universityRefSnap = await getDoc(doc.ref.parent.parent)
                    let universityData = await universityRefSnap.data()

                    courseData.university = await { ...universityData, ...{ docid: universityRefSnap.id } }
                    // Push the combined data to the array
                    coursesWithUniversities.push(courseData);
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: coursesWithUniversities
                })
            }).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Remove Course into University
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async removeCourseFromUniversity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            // Reference to the course document within the 'courses' subcollection
            const courseRef = doc(db, collectionName.universities, payload.university, collectionName.courses, payload.docid);

            deleteDoc(courseRef).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Courses Removed Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },


    /**
     * @description get Course into from University
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getCourseInfoFromUniversity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            // Reference to the course document within the 'courses' subcollection
            const courseRef = doc(db, collectionName.universities, payload.university, collectionName.courses, payload.course);

            getDoc(courseRef).then((documentSnapshot) => {
                if (documentSnapshot.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: { ...documentSnapshot.data(), ...{ docid: documentSnapshot.id } }
                    })
                } else {
                    resolve({
                        success: true,
                        message: 'Courses Not Avaialabe'
                    })
                }

            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },


    /**
     * @description Get All Courses In University
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getAllCoursesInUniversity({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let courses = []
            // Reference to the course document within the 'courses' subcollection
            const courseRef = query(collection(db, `${collectionName.universities}/${docid}/${collectionName.courses}`));

            getDocs(courseRef).then((documentSnapshot) => {
                documentSnapshot.forEach(docSnap=>{
                    courses.push({...docSnap.data(), ...{docid: docSnap.id}})
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: courses
                })

            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};