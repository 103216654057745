/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import {
    signOut,
    signInWithPopup,
    GoogleAuthProvider
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import { auth, db } from "@/config/firebase";
import router from "@/router";

const state = {
    currentUser: null,
}

const getters = {
    getUserInfo: (state) => state.currentUser
}

const mutations = {
    setUserInfo: (state, payload) => state.currentUser = payload,
    clearUserInfo: (state) => state.currentUser = null
}

const actions = {
    /**
     * @description Login with Google
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async LoginWithGoogle({ commit }) {
        const provider = new GoogleAuthProvider();
        try {
            let userInfo = await signInWithPopup(auth, provider);
            commit('setUserInfo', userInfo.user)
            window.location.reload()
            // router.push('/')
            return {
                success: true,
                data: userInfo.user,
            };
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },

    /**
     * @description Logout User
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async LogoutUser({ commit }) {
        console.log('logout');
        try {
            await signOut(auth);
            commit('clearUserInfo')
            if (window.location.pathname != '/login') {
                router.replace('/login')
            }
            return {
                success: true,
                message: "User Logout Successfully",
            };
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },

    /**
     * @description Fetch User
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async FetchUser({ commit }) {
        console.log('Calling Fetch');
        try {
            let user = auth.currentUser
            // console.log('User: ', user);
            if (user == null) {
                await signOut(auth);
                commit('clearUserInfo')
                // commit('clearRole')
            } else {
                commit('setUserInfo', user)
                let docRef = await doc(db, 'admin', user.uid)
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    commit('setUserInfo', user)
                    // commit('setRole', docSnap.data().type)

                    // // Get Config
                    // let configRef = await doc(db, 'config', 'config')
                    // const configdocSnap = await getDoc(configRef);
                    // commit('setBtnType', configdocSnap.data().btnType)
                    return
                } else {
                    await signOut(auth);
                    commit('clearUserInfo')
                    // commit('clearRole')
                    return
                }
            }
            return
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },



}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};