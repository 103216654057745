<template>
  <v-main class="pa-0 ma-0">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="11" class="py-0">
          <v-container
            fluid
            style="background-color: white; border-radius: 12px"
            class="pa-7"
          >
            <v-row>
              <v-col>
                <v-img
                  width="200px"
                  :src="require('@/assets/img/logo.svg')"
                ></v-img>
                <h1 class="mt-10">
                  Hello {{ $store.state.authStore.currentUser.displayName }}!
                </h1>
                <p class="mb-0">One Platform to Manage Website.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2" v-for="(item, index) in summery" :key="index">
                <v-container
                  fluid
                  style="background-color: #f0f0f0; border-radius: 12px"
                >
                  <v-row justify="center" align="center" class="px-md-1">
                    <v-col md="6">
                      <v-icon size="20">{{ item.icon }}</v-icon>
                      <p class="my-1" style="font-size: 90%">{{ item.name }}</p>
                    </v-col>
                    <v-col md="6" class="text-right">
                      <p class="my-0" style="font-size: 200%">
                        {{ item.value }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "HomePage",
  data: () => ({
    summery: [
      {
        icon: "mdi-school-outline",
        value: 0,
        name: "Universities",
      },
      // {
      //   icon: "mdi-newspaper-variant-multiple",
      //   value: 0,
      //   name: "Courses",
      // },
      {
        icon: "mdi-package-variant-closed",
        value: 0,
        name: "Programs",
      },
      {
        icon: "mdi-lan",
        value: 0,
        name: "Leads",
      },
    ],
  }),
  components: {},
  async created() {
    await this.getAllLeads();
    await this.getAllUniversity();
    await this.getAllPrograms();
  },
  methods: {
    async getAllLeads() {
      try {
        let res = await this.$store.dispatch("leadStore/getLeadsByOrder");
        if (res.success) {
          this.summery.filter((summ) => summ.name == "Leads")[0].value =
            res.data.length;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getAllUniversity() {
      try {
        let res = await this.$store.dispatch(
          "universityStore/getUniversitiesByOrder"
        );
        if (res.success) {
          this.summery.filter((summ) => summ.name == "Universities")[0].value =
            res.data.length;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getAllPrograms() {
      try {
        let res = await this.$store.dispatch("programStore/getProgramsByOrder");
        if (res.success) {
          this.summery.filter((summ) => summ.name == "Programs")[0].value =
            res.data.length;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
