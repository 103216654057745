import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    getDoc,
    deleteDoc,
    // setDoc
} from "firebase/firestore"

const collectionName = 'talks'

const state = {
    allProjects: []
}

const getters = {

}

const mutations = {
    doNothing: (state)=> state
}

const actions = {
    /**
     * @description Add Project
     * @param {Object} data 
     * @returns {Object} Object
    */
    async addProject({ commit, rootState }, data) {
        data.createdAt = serverTimestamp()
        data.createdBy = rootState.authStore.currentUser.uid
        return new Promise((resolve, reject) => {
            addDoc(collection(db, collectionName), data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Project Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    /**
     * @description Get All Projects
     * @returns {Object} Object
    */
    async getProjects({ commit }) {
        let projects = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName)).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    projects.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: projects
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },


    /**
     * @description Update Project
     * @param {Object} - Updated Data with Doc Id
     * @returns {Object} Object
    */
    async updateProject({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName, data.docid)
            data.updatedAt = serverTimestamp()
            data.updatedBy = rootState.authStore.currentUser.uid
            delete data.docid
            updateDoc(docRef, data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Project Updated'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get Project info by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getProjectById({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName, docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },


    /**
     * @description Remove Project by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async removeProject({ commit }, docid) {
        return new Promise((resolve, reject) => {
            deleteDoc(doc(db, collectionName, docid)).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Project Removed Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};