import { collectionName } from "@/config/collections";
import { db } from "@/config/firebase";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";

const state = {

}

const mutations = {
    doNothing: state=>state
}

const getters = {

}

const actions = {
    async getNextSequentialId({commit}, entity) {
        console.log('calling  ', entity);
        const docRef = doc(db, collectionName.sequenceInfo, entity);
        const docSnap = await getDoc(docRef);
        let currentId = docSnap.data()?.currentId || 0;
        currentId++;
        await updateDoc(docRef, { currentId, updatedAt: serverTimestamp() });
        commit('doNothing')
        return currentId;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
