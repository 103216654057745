import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    getDoc,
    deleteDoc,
    orderBy,
    query
    // setDoc
} from "firebase/firestore"

const collectionName = 'programs'

const state = {
    allPrograms: []
}

const getters = {

}

const mutations = {
    doNothing: (state) => state
}

const actions = {
    /**
     * @description Add Program
     * @param {Object} data 
     * @returns {Object} Object
    */
    async addProgram({ commit, rootState }, data) {
        data.createdAt = serverTimestamp()
        data.createdBy = rootState.authStore.currentUser.uid
        return new Promise((resolve, reject) => {
            addDoc(collection(db, collectionName), data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Program Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    /**
     * @description Get All Programs
     * @returns {Object} Object
    */
    async getPrograms({ commit }) {
        let programs = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName)).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    programs.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: programs
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get All Programs By Order
     * @returns {Object} Object
    */
    async getProgramsByOrder({ commit }) {
        console.log('asdasd asd s');
        let programs = []
        let orderQuery = query(collection(db, collectionName), orderBy('createdAt', 'desc'));

        return new Promise((resolve, reject) => {
            getDocs(orderQuery).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    programs.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                console.log('asd', programs);
                resolve({
                    success: true,
                    data: programs
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },


    /**
     * @description Update Program
     * @param {Object} - Updated Data with Doc Id
     * @returns {Object} Object
    */
    async updateProgram({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName, data.docid)
            data.updatedAt = serverTimestamp()
            data.updatedBy = rootState.authStore.currentUser.uid
            delete data.docid
            updateDoc(docRef, data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Program Updated'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get Program info by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async getProgramById({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName, docid)
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Remove Program by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async removeProgram({ commit }, docid) {
        return new Promise((resolve, reject) => {
            deleteDoc(doc(db, collectionName, docid)).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Program Removed Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};