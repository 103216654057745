<template>
  <v-navigation-drawer
    clipped
    color="#F5F8FC"
    floating
    v-model="drawer"
    app
    width="300px"
  >
    <!-- <v-list-item class="py-n5 px-4">
      <v-btn class="py-6" depressed dark color="#0048D0" style="margin-left: auto;margin-right: auto;width: 260px;border-radius: 8px;">+ Add Requirement</v-btn>
    </v-list-item> -->

    <!-- style="border: 1px solid #e0e0e0 !important" -->
    <v-list-item class="">
      <!-- {{ user }} -->
        <v-list-item-avatar>
          <v-img
            :src="
              user.photoURL"
          ></v-img>
        </v-list-item-avatar>
  
        <v-list-item-content>
          <v-list-item-title
            class="google-font"
          >{{ user?.displayName || 'User Name' }}</v-list-item-title>
          <v-list-item-subtitle
            class="google-font"
          >{{ user?.email || 'example@gmail.com' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    <v-divider></v-divider>
    <!-- Home -->
    <v-list dense shaped>
      <v-list-item to="/" dense color="primary">
        <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <v-list-item to="/universities" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-school-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Universities</v-list-item-title>
      </v-list-item>

      <v-list-item to="/courses" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-newspaper-variant-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Courses</v-list-item-title>
      </v-list-item>

      <v-list-item to="/programs" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-package-variant-closed</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Programs</v-list-item-title>
      </v-list-item>

      <v-list-item to="/leads" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-lan</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Leads</v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append>

      <v-divider></v-divider>
      <div class="pa-3">
        <p>
          <span>
            <a
              href="https://vrijraj.xyz"
              target="_blank"
              style="text-decoration: none"
              >Vrijraj</a
            >
          </span>
          <span
            class="google-font"
            style="color: #616161; font-size: 80%; float: right"
            >Version: 0.0.1
          </span>
        </p>
      </div>
    </template>
  </v-navigation-drawer>
</template>
  
  <script>

export default {
  name: "AppDrawer",
  data: () => ({
    mini: true,
  }),
  mounted() {},
  computed: {
    user:{
      get() {
        return this.$store.state.authStore.currentUser;
      },
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
};
</script>
  