import { db } from "@/config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    deleteDoc,
    orderBy,
    query
} from "firebase/firestore"

const collectionName = 'leads'

const state = {
    allPrograms: []
}

const getters = {

}

const mutations = {
    doNothing: (state) => state
}

const actions = {
    /**
     * @description Add Lead
     * @param {Object} data 
     * @returns {Object} Object
    */
    async addLead({ commit, rootState }, data) {
        data.createdAt = serverTimestamp()
        data.createdBy = rootState.authStore.currentUser.uid
        return new Promise((resolve, reject) => {
            addDoc(collection(db, collectionName), data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Lead Added Successfully'
                })
            })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    /**
     * @description Get All Leads
     * @returns {Object} Object
    */
    async getLeads({ commit }) {
        let programs = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, collectionName)).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    programs.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: programs
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    /**
     * @description Get All Leads By Order
     * @returns {Object} Object
    */
    async getLeadsByOrder({ commit }) {
        let leads = []
        let orderQuery = query(collection(db, collectionName), orderBy('createdAt', 'desc'));

        return new Promise((resolve, reject) => {
            getDocs(orderQuery).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    leads.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: leads
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },


    /**
     * @description Update Lead
     * @param {Object} - Updated Data with Doc Id
     * @returns {Object} Object
    */
    async updateLead({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, collectionName, data.docid)
            data.updatedAt = serverTimestamp()
            data.updatedBy = rootState.authStore.currentUser.uid
            delete data.docid
            updateDoc(docRef, data).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Lead Updated'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },


    /**
     * @description Remove Lead by Id
     * @param {Number} - Doc Id
     * @returns {Object} Object
    */
    async removeLead({ commit }, docid) {
        return new Promise((resolve, reject) => {
            deleteDoc(doc(db, collectionName, docid)).then(() => {
                commit('doNothing')
                resolve({
                    success: true,
                    message: 'Lead Removed Successfully'
                })
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};